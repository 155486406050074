@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.aiManagerWrapper {
    @include common.tablePageWrapper;
    .aiManagerTitleContainer {
        @include common.flexRow;
        height: 45px;
        align-items: center;
        padding: 0 10px 0 10px;
        .aiManagerTitleWrapper {
            display: flex;
            width: 50%;
            .aiManagerTitle {
                @include common.tablePageTitle;
                margin-left: calc(8vw - 10px);
            }
        }
        .dropdownWrapper {
            @include common.flexRow;
            width: calc((100% * 0.5) - 25px);
        }
    }
    .aiManagerContentWrapper {
        @include common.flexRow;
        height: calc(100% - 60px);
        padding: 10px;
        .aiPieChartWrapper {
            @include common.centreFlexColumn;
            justify-content: center;
            padding: 10px 0;
            margin-right: 10px;
            position: relative;
            .pieChartWrapper {
                @include common.flexColumn;
                position: relative;
                justify-content: center;
                width: 100%;
                .pieChartIcon {
                    @include common.centreFlexColumn;
                    color: theme.$color-primary-night;
                    position: absolute;
                    border-radius: 50%;
                    top: calc(100% / 3);
                    left: calc(100% / 3);
                    .correctPercentage {
                        font-size: 30px;
                        font-weight: theme.$font-bold;
                    }
                }
            }
            .successRatioWrapper {
                @include common.centreFlexColumn;
                width: 100%;
                .successRatioHeader {
                    @include common.centreFlexColumn;
                    width: 100%;
                    font-size: 18px;
                    font-weight: theme.$font-medium;
                }
                .successRatio {
                    @include common.centreFlexColumn;
                    width: 100%;
                    font-size: 22px;
                    font-weight: theme.$font-medium;
                }
            }
        }
        .aiAgreementWrapper {
            @include common.flexColumn;
        }
    }
}

.agreementTypeSelectWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    .agreementTypeWrapper {
        @include common.flexColumn;
        @include common.transparentButton;
        justify-content: flex-end;
        border: 1px solid theme.$color-light-grey;
        border-radius: 5px;
        padding: 10px;
        width: calc(100% - 22px);
        margin-bottom: 10px;
        &:hover {
            box-shadow: 1px 1px 5px theme.$color-french;
        }
        .agreementTypeLabel {
            text-align: right;
            width: 100%;
            font-weight: theme.$font-medium;
            font-size: 18px;
        }
        .documentNames {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.percentageWrapper {
    @include common.flexColumn;
    width: 100%;
    .percentageBar {
        @include common.spaceBetweenFlexRow;
        height: 10px;
        width: calc(100% - 10px);
        margin: 5px 5px 2px 5px;
        border-radius: 5px;
        background-color: theme.$color-amber;
        border: 1px solid theme.$color-light-grey;
        .successPercentage {
            position: relative;
            margin-top: -1px;
            height: 100%;
            border-radius: 5px;
            background-color: theme.$color-mandatory;
            border: 1px solid theme.$color-mandatory;
            box-shadow: 1px 1px 3px theme.$color-mandatory;
        }
    }
    .successInfo {
        @include common.spaceBetweenFlexRow;
        align-items: center;
        width: 100%;
        font-size: 12px;
        .successString {
            @include common.flexRow;
            width: calc(80% - 10px);
            margin-right: 10px;
            align-items: center;
            justify-content: flex-start;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
        }
        .displayPercentage {
            @include common.flexRow;
            width: 20%;
            align-items: center;
            justify-content: flex-end;
            font-weight: theme.$font-bold;
            color: theme.$color-primary-night;
        }
    }
}

.aiQueriesWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    .aiQueriesTitle {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: theme.$font-bold;
    }
    .aiQueriesButtons {
        @include common.flexRow;
        justify-content: space-between;
        .leftButtons {
            @include common.flexRow;
        }
        .rightButtons {
            @include common.flexRow;
        }
    }
}

.aiQueries {
    @include common.flexColumn;
    width: 100%;
    height: calc(100% - 118px);
    margin-bottom: 10px;
    .emptyQueries {
        @include common.centreFlexColumn;
        height: 100%;
    }
    .aiQueryWrapper {
        @include common.flexColumn;
        border: 1px solid theme.$color-light-grey;
        border-radius: 5px;
        padding: 10px;
        width: calc(100% - 22px);
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        .aiDataPointWrapper {
            @include common.flexRow;
            align-items: flex-start;
            margin-bottom: 5px;
            .aiDataPointLabel {
                @include common.flexRow;
                text-align: start;
                width: calc(30% - 10px);
                margin-right: 10px;
                font-weight: theme.$font-medium;
                align-items: center;
                .edgeCaseTooltip {
                    margin-left: 5px;
                }
            }
        }
        .documentNamesWrapper {
            @include common.flexColumn;
        }
        .deleteQuery {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.defaultValueWrapper {
    @include common.flexRow;
    align-items: center;
    width: 70%;
    .dropdownFieldWrapper {
        @include common.flexRow;
        width: 100%;
    }
}

.reorderModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 80vw;
    height: 90vh;
    .reorderWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        padding: 10px;
        .scrollableWrapper {
            @include common.flexColumn;
            height: calc(100% - 114px);
            width: 100%;
            margin-bottom: 10px;
            .queryTile {
                @include common.flexRow;
                background: theme.$color-french-spectrum-3;
                border-radius: 5px;
                padding: 10px;
                margin: 10px;
                justify-content: space-between;
                transition: all 0.2s ease-in-out;
                &:active {
                    box-shadow: 1px 1px 5px theme.$color-french;
                    background: theme.$color-french-spectrum-2;
                }
                &:hover {
                    transform: scale(1.01);
                }
                .questionIndex {
                    @include common.centreFlex(row);
                    width: 50px;
                    font-size: theme.$font-size-h1;
                    font-weight: theme.$font-bold;
                    color: theme.$color-white;
                }
                .infoWrapper {
                    width: calc(100% - 50px);
                    @include common.flexColumn;
                    justify-content: space-between;
                    .query {
                        @include common.flexColumn;
                        color: theme.$color-white;
                        font-size: theme.$font-size-h4;
                        font-weight: theme.$font-bold;
                        cursor: grab !important;
                    }
                    .queryIdWrapper {
                        @include common.flexRow;
                        margin-top: 10px;
                        justify-content: space-between;
                        .queryId {
                            color: theme.$color-white;
                            font-weight: theme.$font-medium;
                            width: calc(50% - 130px);
                        }
                        .documentNameIds {
                            @include common.flexRow;
                            width: 120px;
                            background-color: theme.$color-white;
                            align-items: center;
                            border-radius: 5px;
                            padding: 3px;
                            .relevantDocumentName {
                                width: 15px;
                                height: 15px;
                                border-radius: 5px;
                                margin-right: 3px;
                                @include common.centreFlex(row);
                            }
                        }
                    }
                }
            }
            .excludedQueryTile {
                transform: scale(0.99);
                background: theme.$color-light-grey;
            }
        }
    }
    .buttonsWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        .documentNameKeys {
            @include common.flexRow;
            width: calc(100% - 310px);
            margin-left: 10px;
            align-items: center;
            .documentKeyLabel {
                color: theme.$color-primary-night;
                font-weight: theme.$font-medium;
                font-size: theme.$font-size-h4;
            }
            .documentKeyWrapper {
                @include common.flexRow;
                width: calc(100% - 170px);
                margin: 0px 10px;
                min-width: 175px;
                .documentKey {
                    min-width: 25px;
                    height: 25px;
                    border-radius: 5px;
                    margin-right: 5px;
                }
            }
        }
        .buttons {
            @include common.flexRow;
            align-items: center;
        }
    }
}

.agreementSelectWrapper {
    width: 100%;
    .agreementSelectTitle {
        margin-bottom: 5px;
    }
}

:export {
    amber: theme.$color-amber;
    gold: theme.$color-gold;
    ocean: theme.$color-ocean;
    french: theme.$color-french-spectrum-4;
    white: theme.$color-white;
    red: theme.$color-red;
    green: theme.$color-mandatory-spectrum-1;
    amethyst: theme.$color-amethyst-spectrum-1;
    primary: theme.$color-primary-night;
}
