@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.documentAnalysisHeader {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    height: 59px;
    .documentTabWrapper {
        @include common.centreFlexColumn;
        height: calc(100% - 1px);
        padding: 0 5px;
        cursor: pointer;
        background-color: theme.$color-lightest-grey;
        border-radius: 5px 5px 0 0;
        outline: none;
        border: none;
        font-family: theme.$font-family;
        .documentTabTitle {
            @include common.overflowText;
            width: 100%;
            color: theme.$color-primary-night;
            font-size: 20px;
            font-weight: theme.$font-medium;
        }
        .tabTitleDisabled {
            color: rgba(theme.$color-grey, 0.4);
            text-decoration: none;
        }
        .documentTabType {
            @include common.overflowText;
            width: 100%;
            color: theme.$color-primary-night;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 5px;
        }
        .tabTypeDisabled {
            color: rgba(theme.$color-grey, 0.4);
            text-decoration: none;
        }
    }
    .selectedTabWrapper {
        border: 1px solid rgba(theme.$color-grey, 0.5);
        border-bottom: none;
        background-color: theme.$color-white;
    }
    .middleTabUnselectedWrapper {
        background-color: theme.$color-unselected-middle-tab !important;
    }
    .unselectedTabWrapper {
        border-bottom: 1px solid rgba(theme.$color-grey, 0.5);
    }
    .tabDisabled {
        cursor: default;
    }
    .leftBoxShadow {
        box-shadow: inset 1px -1px 3px 1px theme.$color-light-grey;
        background-image: linear-gradient(to right, theme.$color-light-grey, theme.$color-lightest-grey 8px);
    }
    .rightBoxShadow {
        box-shadow: inset -1px -1px 3px 1px theme.$color-light-grey;
        background-image: linear-gradient(to left, theme.$color-light-grey, theme.$color-lightest-grey 8px);
    }
}

.documentAnalysis {
    border: 1px solid rgba(theme.$color-grey, 0.5);
    border-top: none;
    border-radius: 0 0 5px 5px;
    height: calc(100% - 60px);
}

.spinnerWrapper {
    align-self: center;
    height: 100%;
    width: 100%;
}

.viewButtonsWrapper {
    @include common.flexColumn;
    width: 100%;
    align-items: center;
    .viewButton {
        @include common.transparentButton(theme.$color-primary-night);
        @include common.centreFlexRow;
        width: 100%;
        padding: 0;
    }
}

.sideMenuWrapper {
    @include common.flexColumn;
    align-items: center;
    width: 40px;
    margin: 0 -5px 0 5px;
    .sectionWrapper {
        @include common.flexColumn;
        align-items: center;
        width: 100%;
        .divider {
            justify-self: center;
            width: 100%;
            height: 2px;
            background-color: theme.$color-primary-night;
            margin: 6px 0;
        }
    }
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    grey: theme.$color-grey;
    lightGrey: theme.$color-light-grey;
    white: theme.$color-white;
}
