@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.wrapper {
    @include common.flexColumn;
    align-items: center;
    height: 100%;
    width: 80%;
    margin: auto;
    margin-top: 20px;
}

.header {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    color: theme.$color-primary-night;
    margin-bottom: 20px;
    .title {
        font-size: 20px;
        font-weight: theme.$font-bold;
    }
}

.inputsWrapper {
    @include common.centreFlexColumn();
    width: 100%;
}

.passwordStrengthWrapper {
    width: 100%;
    @include common.flexColumn;
    .barsWrapper {
        width: 375px;
        @include common.flexRow;
        justify-content: space-between;
        .bar {
            height: 8px;
            width: 23%;
            border-radius: 5px;
            background-color: theme.$color-grey;
        }
    }
    .passwordStrength {
        display: flex;
        width: 375px;
        height: 24px;
        font-family: theme.$font-family;
        font-weight: theme.$font-medium;
        font-size: theme.$font-size-h3 !important;
        justify-content: flex-end;
    }
}

.passwordWrapper {
    @include common.flexColumn;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 5px;
    .passwordInputLabel {
        font-weight: theme.$font-medium;
        text-decoration: underline;
        color: theme.$color-primary-night;
    }
    .passwordInputWrapper {
        @include common.flexRow;
        width: 100%;
        .passwordInput {
            margin-bottom: 10px;
            border: 1px solid theme.$color-primary-night;
            border-radius: 5px;
            color: theme.$color-primary-night;
            margin-top: 5px;
            margin-right: 5px;
            flex: 1;
            &:focus {
                outline: none;
                border: 1px solid theme.$color-french;
            }
        }
    }
}

.errorMessage {
    display: flex;
    align-items: center;
    color: theme.$color-red;
    font-weight: theme.$font-medium;
    font-size: theme.$font-size-h3;
}

.buttonWrapper {
    @include common.flexRow;
    width: 100%;
    justify-content: space-between;
}

.forgottenPasswordWrapper {
    width: 100%;
    .forgotPassword {
        @include common.transparentButton;
        margin-top: 10px;
        font-weight: theme.$font-medium;
        color: theme.$color-french;
        cursor: pointer;
        font-size: 14px;
    }
}


.resetWithLinkWrapper {
    @include common.centreFlex(column);
    @include common.loginBackground;
    color: theme.$color-white;
    height: 100%;
}

.logoWrapper {
    @include common.logoWrapper;
    margin-top: 150px;
}

.resetPasswordButtonWrapper {
    @include common.flexRow;
    align-items: center;
}

.resetPasswordButton {
    @include common.loginButton;
    width: 200px;
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.passwordReset {
    @include common.loginInput;
}

.newUserWrapper {
    @include common.flexColumn;
    margin-bottom: 20px;
    .newUserWelcome {
        font-size: 25px;
        font-weight: theme.$font-bold;
        margin-bottom: 10px;
    }
    .newUserNote {
        font-weight: theme.$font-medium;
        font-size: 16px;
    }
}

:export {
    white: theme.$color-white;
    grey: theme.$color-grey;
    red: theme.$color-red;
    gold: theme.$color-gold;
    amber: theme.$color-amber;
    yellowGreen: theme.$color-yellow-green;
    green: theme.$color-green;
}
