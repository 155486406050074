@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.viewSelectorWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
}

.searchDocumentsWrapper {
    @include common.flexColumn;
    align-self: center;
    width: 90%;
    height: 100%;
}

.searchDocumentsHeader {
    @include common.centreFlexRow;
    .searchDocumentsTitle {
        font-size: 22px;
        font-weight: theme.$font-bold;
        margin-right: 10px;
    }
}

.searchTypeAndInputWrapper {
    @include common.flexRow;
    min-height: 50px;
}

.searchTypeWrapper {
    @include common.centreFlexRow;
    height: 50px;
    width: 300px;
    .radioWrapper {
        width: calc(100% - 24px);
    }
}

.smartSearchAndResultsWrapper {
    display: flex;
    min-height: 90%;
    width: 100%;
}

.resultsAndPaginationWrapper {
    @include common.flexColumn;
    margin-bottom: 5px;
    .paginationAndTotalWrapper {
        margin-left: 85px;
        @include common.flexRow;
        .resultsTotal {
            height: 20px;
            width: 85px;
            padding: 5px;
            color: theme.$color-french;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
        }
    }
}

.smartSearchWrapper {
    min-width: 45%;
    margin-right: auto;
    height: 100%;
    padding: 5px;
}

.searchResultsWrapper {
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    .noResults {
        margin-top: 20px;
        font-size: 22px;
        font-weight: theme.$font-medium;
    }
}

.resultWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    border-radius: 5px;
    margin: 10px 20px;
    padding: 20px;
    font-family: theme.$font-family;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    color: theme.$color-primary-night;
    cursor: pointer;
    &:hover {
        box-shadow: 1px 1px 5px theme.$color-french;
        transform: scale(1.02);
    }
    .resultHeader {
        @include common.spaceBetweenFlexRow;
        margin-bottom: 20px;
        width: 100%;
        .resultHeaderColumn {
            @include common.flexColumn;
            width: 65%;
        }
        .resultHeaderEntityColumn {
            @include common.flexColumn;
            width: 30%;
        }
    }
    .resultDocumentName {
        text-align: left;
        font-size: 16px;
        font-weight: theme.$font-medium;
    }
    .resultDescription {
        text-align: left;
        font-size: 22px;
        font-weight: theme.$font-bold;
    }
    .entityNameWrapper {
        @include common.flexRow;
    }
    .resultEntity {
        text-align: end;
        font-size: 14px;
        font-weight: theme.$font-medium;
        height: 20px;
        @include common.overflowText;
        width: 100%;
    }
    .resultIconEntity {
        text-align: end;
        font-size: 14px;
        font-weight: theme.$font-medium;
        height: 20px;
        @include common.overflowText;
        width: calc(100% - 20px);
    }
    .resultPagesHeader {
        @include common.flexRow;
        width: 100%;
        padding-bottom: 3px;
        margin-bottom: 3px;
        border-bottom: 1px solid rgba(theme.$color-grey, 0.5);
        font-weight: theme.$font-medium;
        text-align: left;
    }
    .resultPagesWrapper {
        @include common.flexColumn;
        width: 100%;
        align-items: flex-start;
        max-height: 80px;
        .resultPage {
            @include common.flexRow;
            width: 100%;
            text-align: left;
            .resultKeyword {
                font-weight: theme.$font-bold;
                color: theme.$color-french;
            }
        }
    }
    .resultButtonsWrapper {
        @include common.flexRow;
        width: 100%;
        .preExecutionLabel {
            width: 100%;
            font-size: theme.$font-size-h3;
            font-weight: theme.$font-medium;
            color: theme.$color-amber;
            align-self: center;
            text-align: end;
        }
        .rightAlignedButtons {
            @include common.flexRow;
            align-items: center;
            align-self: flex-end;
            width: fit-content;
            margin-left: 10px;

        }
    }
}

.documentPreviewModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    .documentPreviewWrapper {
        @include common.flexColumn;
        width: 50vw;
        .documentPreview {
            @include common.centreFlexColumn;
            border-top: none;
            margin: 20px;
            height: 80vh;
        }
        .buttonWrapper {
            @include common.flexRow;
            width: calc(100% - 40px);
            align-items: center;
            justify-content: flex-end;
            margin: 0 20px 20px 20px;
        }
    }
}

.smartInputWrapper {
    align-items: center;
    .buttonWrapper {
        @include common.centreFlexRow;
        width: calc(100% - 40px);
        align-self: center;
        justify-content: space-between;
        .smartSearchButtonWrapper {
            @include common.flexRow;
        }
    }
}

.conditionSeparator {
    margin: 0px 45px 5px 15px;
    padding: 2px 0;
    border-bottom: dashed theme.$color-french 3px;
}

.smartInputRow {
    @include common.centreFlexRow;
    width: 100%;
    margin-bottom: 5px;
    padding: 2px 0;
    .logicalOperatorColumn {
        width: 16%;
        margin-right: 10px;
        .firstRowTitle {
            text-align: left;
            font-weight: theme.$font-medium;
        }
    }
    .conditionColumn {
        width: 24%;
        margin-right: 10px;
    }
    .inputWrapper {
        width: 60%;
        margin-right: 10px;
    }
    .deletePlaceholder {
        width: 20px;
    }
}

.filterWrapper {
    @include common.flexColumn;
    justify-content: space-between;
    padding: 0 5px;
    height: fit-content;
    max-height: 500px;
    color: theme.$color-primary-night;
    .searchFilterButtonWrapper {
        @include common.flexRow;
        width: 100%;
        padding-top: 10px;
        justify-content: space-between;
        .leftButtonWrapper {
            @include common.flexRow;
        }
        .rightButtonWrapper {
            @include common.flexRow;
        }
    }
}
